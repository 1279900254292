<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
      >
        <v-toolbar-title>New Price List</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          small
          text
          to="/inventory/price-list"
        >
          <v-icon
            left
            dark
          >mdi-keyboard-backspace</v-icon>Back
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <!-- editor -->
        <editor
          :currencies="currencies"
          :initial="dataItem"
          :createStatus="createStatus"
          @data="save"
        ></editor>
        <!-- end -->
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Editor from "../_components/editor.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Editor,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data: () => ({
    dataItem: {},
    currencies: [],
    createStatus: true,
  }),
  methods: {
    save(data) {
      const url = "/price_lists";
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then(() => {
          // self.$refs.snackbar.show("Item group created", "green");
          self.$router.push("/inventory/price-list");
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
    getCurrencies() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/currency`)
        .then((res) => {
          self.currencies = res;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getCurrencies();
  },
};
</script>